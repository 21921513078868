import React from 'react'
import classNames from 'classnames'
import styles from './Newsletter.module.scss'

const Newsletter = () => (
  <form action="https://activatedapps.us12.list-manage.com/subscribe/post?u=6349707ac84dc6562c6490f25&amp;id=b61cd818b9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className={styles.form} target="_blank" noValidate>
    <div>
      <h2 className={styles.text}>Join Our Newsletter</h2>
      <p className={styles.description}>
        Receive the latest product updates and ecommerce tips straight to your inbox.
      </p>
    </div>
    <div className={styles.actions}>
      <input type="email" placeholder="Email address" name="EMAIL" className={classNames('required', styles.email)} id="mce-EMAIL"/>
      <div style={{'position': 'absolute', 'left': '-5000px'}} aria-hidden="true"><input type="text" name="b_6349707ac84dc6562c6490f25_b61cd818b9" tabIndex="-1" /></div>
      <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={styles.button}/>
    </div>
  </form>
)

export default Newsletter