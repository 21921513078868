import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import moment from 'moment'
import styles from './blog-page.module.scss'

// Components
import Layout from '../components/Layout'
import Section from '../components/Layout/Section'
import PageTitle from '../components/Elements/PageTitle'
import Newsletter from '../components/Elements/Newsletter'
import Content, { HTMLContent } from '../components/Blog/Content'

export const BlogPageTemplate = ({
  title,
  content,
  contentComponent,
  date
}) => {
  const BlogContent = contentComponent || Content
  return (
    <div>
      <PageTitle title={title}>
        <span className={styles.date}>{moment(date).format('MMM Do, YYYY')}</span>
      </PageTitle>
      <Section>
        <BlogContent content={content} />
        <Newsletter />
      </Section>
    </div>
  )
}

const BlogPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <Helmet>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@activatedapps" />
        <meta property="og:url" content={`https://activatedapps.com${post.fields.slug}`} />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={`https://activatedapps.com${post.frontmatter.image}`} />
      </Helmet>
      <BlogPageTemplate
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      frontmatter {
        title
        date
        image
      }
      fields {
        slug
      }
    }
  }
`

export default BlogPage